import { checkout } from "./services/checkout";
import formatAmount from "./services/formatAmount";

const Product = ({ title, image, description, price, priceId }) => {
  console.log(priceId);
  return (
    <div className="product">
      <img src={image} alt={`${title}`} />
      <h2>{title}</h2>
      <div>{formatAmount(price)}</div>
      <div>{description}</div>
      <button
        onClick={() => {
          checkout(priceId);
        }}
      >
        Buy Now
      </button>
    </div>
  );
};

export default Product;
