import Product from "./Product";

const ProductList = ({ products }) => {
  return (
    <div className="products-list">
      {products &&
        products.map((product) => {
          const { name, id, description } = product;
          const image = product.images ? product.images[0] : "";
          console.log(product.prices[0].unit_amount);
          const price = product.prices[0].unit_amount_decimal
            ? product.prices[0].unit_amount_decimal
            : 0;
          const priceId = product.prices[0].id ? product.prices[0].id : "";

          return (
            <Product
              key={id}
              title={name}
              image={image}
              price={price}
              description={description}
              priceId={priceId}
            />
          );
        })}
    </div>
  );
};

export default ProductList;
